
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { useRouter } from "vue-router";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "underwriter-client-details",
  components: {
    GoBackButton,
  },
  data() {
    return {
      summary: {},
      policies: [
        {
          publicId: "",
          businessTypeShortCode: "",
          startDate: "",
          expiryDate: "",
          status: "",
          sumInsured: 0,
          year: "",
          type: "",
          number: "",
          reference: "",
          premium: 0,
        },
      ],
      claims: [
        {
          claimNumber: "",
          policyType: "",
          entity: "",
          lossType: "",
          status: "",
          amount: "",
          settledAmount: "",
          outstandingAmount: "",
          lossDate: "",
          reportedDate: "",
          publicId: "",
          hasClaimNumber: false,
          isSettlementOfferAccepted: false,
          settlementOffer: {},
          isDischargeVoucherApproved: false,
        },
      ],
      publicId: this.$route.params.publicId,
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Client Information", ["Clients"]);
      displayDatatable(["basicExample", "basicExample1"]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      setActiveTab,
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get policy information
    this.getPolicyInformation();

    //Set page title
    document.title = "Client Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    gotoUrl(event) {
      this.$router.push(event);
    },
    getPolicyInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.UNDERWRITERS_CLIENTS_DETAILS_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to variables
            this.summary = data.data.summary;
            this.policies = data.data.policies;
            this.claims = data.data.claims;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    handleClick(item) {
      const navigationType = [
        "HOM-HOU-OWN",
        "HOM-HOU",
        "HOM-HOU-OWN-",
        "HOM-HOU",
      ].includes(item.businessTypeShortCode)
        ? "underwriter-house-insurance-owned-building-policy-information"
        : item.businessTypeShortCode === "HOM-HOU-TEN"
        ? "underwriter-house-insurance-rented-building-policy-information"
        : "underwriter-motor-insurance-policy-information";

      this.useReusableNavigation(false, navigationType, {
        publicId: item.publicId,
      });
    },
  },
});
